/** @format */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  padding: 0;
  width: 100%;
  height: 100vh;
  font-size: 14px;
  font-weight: 500;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  background: darkgrey;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(54, 54, 57);
  border-radius: 8px;
}

a {
  text-decoration: none !important;
  color: #1e293b;
}

a:hover {
  color: #1e293b;
}

h1 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
}

h2 {
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
}

h3 {
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
}

h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
}

span {
  font-size: 12px;
}

small {
  font-size: 10px;
}

input {
  width: 100%;
  height: 60px !important;
  padding: 0px, 12px, 0px, 12px;
  border-radius: 12px !important;
  border: 1px solid #cbd5e1;
  outline: none;
  box-shadow: none !important;
}

label {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
}

button {
  background: none;
  border: none;
  width: 100%;
  height: 56px;
  padding: 0px, 14px, 0px, 14px;
  border-radius: 32px;
}

ul {
  margin: 0;
  padding: 0;
}
table tbody td {
  white-space: nowrap !important;
}

table thead th {
  white-space: nowrap !important;
  text-transform: uppercase;
}
