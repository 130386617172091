/** @format */
.app-btn-primary {
  background: #453cdb;
  color: #ffffff;
  font-weight: 600;
}
.app-btn-primary:hover {
  background: #453cdb;
}
.form-control {
  box-shadow: none !important;
  border-radius: 12px !important;
  background: none !important;
}
.p-column-title {
  white-space: nowrap !important;
}

.p-paginator button {
  width: unset !important;
}
