/** @format */

.sidebar {
  height: 100% !important;
  /* width: 100%; */
  /* background: #fff !important; */
  transition: all 0.3s;
}
.navbar_nav {
  height: 60vh;
  overflow-x: auto;
}
.nav_item {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
}
.active {
  background: #4b5366;
  border-radius: 6px;
  color: #ffffff !important;
  font-weight: 600;
}
.active .icon {
  color: #f3f4f8;
}
.link {
  color: #ffffff !important;
}
.icon {
  color: #ffffff;
  font-size: 20px;
}
.nav_item a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  width: 100%;
}

.logout_container small {
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
}
.logout_container {
  height: 20vh;
  display: flex;
  align-items: flex-end;
}
@media screen and (max-width: 991px) {
  .sidebar {
    width: 260px;
    transition: all 0.3s;
    height: 100vh !important;
  }
}
