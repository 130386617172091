/** @format */

.verify_paragraph {
  font-size: 16px;
  font-weight: 900;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.paragraph_label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #605656;
}
.list_style {
  padding-left: 28px !important;
}
.list_style li {
  font-size: 14px;
}
.list_style li small {
  font-size: 12px;
}
.userInfo {
  font-size: 14px;
}
.userInfo span {
  font-size: 14px;
}
