/** @format */

.layout {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
}
.sidebar {
  width: 260px;
  height: 100vh;
  padding: 20px 14px;
  background: #0f172a;
}
.main_content {
  width: calc(100% - 260px);
  height: 100vh;
}

.active {
  width: 100%;
}

.header {
  height: 10vh;
  background-color: #ffffff;
}
.main_container {
  padding: 20px 14px;
  height: 90vh;
  overflow-x: auto;
}
.passwordVisibility {
  position: absolute;
  top: 8px;
  right: 16px;
  cursor: pointer;
  font-size: 22px;
}

@media screen and (max-width: 991px) {
  .main_content {
    width: 100%;
  }
  .sidebar {
    width: 290px;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
