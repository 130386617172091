/** @format */

.auth_page {
  height: 100vh;
  width: 100%;
  background: linear-gradient(
    rgb(54, 54, 94),
    rgb(28, 27, 39),
    rgb(29, 28, 41)
  );
  text-align: center;
  color: #ffffff;
  padding: 96px 0px 96px 0px;
}
